.mapLeftSection {
  position: absolute;
  z-index: 20;
  top: 0;
  left: 0;
  width: 420px;
  min-width: 420px;
  margin: 8px 0 8px 20px;
}

.mapMainGroup {
  position: absolute;
  top: 0;
  right: 0;
  margin: 8px 20px 0 0;
  display: flex;
  align-items: flex-start;
}

.marginLeft {
  margin-left: 5px;
}

.closePanelWrap {
  position: absolute;
  display: inline-block;
  line-height: 45px;
  margin-left: 10px;
  right: -30px;
  top: 0px;
}

.closePanelBtn {
  background-color: #4d4d4d;
  color: #fff;
  padding: 12px 4px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  font-size: 7px;
  vertical-align: top;

  &:hover {
    background-color: grey;
  }

  svg {
    transform: rotate(-90deg);
  }
}
.closePanelBtnShow {
  svg {
    transform: rotate(90deg);
  }
}

.actionButtonGroup {
  display: flex;
  justify-content: flex-end;
  align-items: flex-center;
  flex-direction: row;
  position: absolute;
  right: 5px;
  top: 5px;
}

.actionButton {
  width: 40px;
  height: 40px;
}

.inputCoordinates {
  width: 100%;
  height: 48px;
  padding: 5px;
  font-family: inherit;
  font-size: medium;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  // animation-fill-mode: forwards;
}

input::placeholder {
  color: #d3d3d3;
  animation-fill-mode: forwards;
  // opacity: 0.9;
}
